window.axios = require('axios');

(function() {
  window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  window.axios.defaults.withCredentials = true
})()

const defaultOptions = {
  // baseURL: <CHANGE-TO-URL>,
  headers: {
    'Content-Type': 'application/json'
  }
}

const $http = window.axios.create(defaultOptions)

$http.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem('web_token')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export default $http
