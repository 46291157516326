<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Twoje strony</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <button class="btn btn-primary shadow-md mr-2" @click="navigate('pages-add')">Dodaj stronę</button>
<!--        <div class="dropdown">-->
<!--          <button-->
<!--            class="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300"-->
<!--            aria-expanded="false"-->
<!--          >-->
<!--            <span class="w-5 h-5 flex items-center justify-center">-->
<!--              <PlusIcon class="w-4 h-4" />-->
<!--            </span>-->
<!--          </button>-->
<!--          <div class="dropdown-menu w-40">-->
<!--            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">-->
<!--              <a-->
<!--                href=""-->
<!--                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"-->
<!--              >-->
<!--                <PrinterIcon class="w-4 h-4 mr-2" /> Print-->
<!--              </a>-->
<!--              <a-->
<!--                href=""-->
<!--                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"-->
<!--              >-->
<!--                <FileTextIcon class="w-4 h-4 mr-2" /> Export to Excel-->
<!--              </a>-->
<!--              <a-->
<!--                href=""-->
<!--                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"-->
<!--              >-->
<!--                <FileTextIcon class="w-4 h-4 mr-2" /> Export to PDF-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="hidden md:block mx-auto text-gray-600">
<!--          Showing 1 to 10 of 150 entries-->
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Szukaj..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">NAZWA</th>
              <th class="text-center whitespace-nowrap">ILOŚĆ OBIEKTÓW</th>
              <th class="text-center whitespace-nowrap">ROZMIAR OBIEKTÓW</th>
              <th class="text-center whitespace-nowrap">STATUS</th>
              <th class="text-center whitespace-nowrap">AKCJE</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              class="intro-x"
            >
              <td>
                <a href="" class="font-medium whitespace-nowrap">
                  {{ item.name }}
                </a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  {{ item.website }}
                </div>
              </td>
              <td class="text-center">
                {{ item.files_count }}
              </td>
              <td class="text-center">
                {{ item.files_sum_size ? humanFileSize(item.files_sum_size) : '0' }}
              </td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-theme-20': item.active === 1,
                    'text-theme-21': item.active === 0
                  }"
                >
                  <CheckSquareIcon class="w-4 h-4 mr-2" />
                  {{ item.active === 1 ? 'Aktywna' : 'Nieaktywna' }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edytuj
                  </a>
                  <a
                    class="flex items-center text-theme-21"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Usuń
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
<!--      <div-->
<!--        class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"-->
<!--      >-->
<!--        <ul class="pagination">-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">-->
<!--              <ChevronsLeftIcon class="w-4 h-4" />-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">-->
<!--              <ChevronLeftIcon class="w-4 h-4" />-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">...</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">1</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link pagination__link&#45;&#45;active" href="">2</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">3</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">...</a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">-->
<!--              <ChevronRightIcon class="w-4 h-4" />-->
<!--            </a>-->
<!--          </li>-->
<!--          <li>-->
<!--            <a class="pagination__link" href="">-->
<!--              <ChevronsRightIcon class="w-4 h-4" />-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--        <select class="w-20 form-select box mt-3 sm:mt-0">-->
<!--          <option>10</option>-->
<!--          <option>25</option>-->
<!--          <option>35</option>-->
<!--          <option>50</option>-->
<!--        </select>-->
<!--      </div>-->
      <!-- END: Pagination -->
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-21 mx-auto mt-3" />
              <div class="text-3xl mt-5">Czy jesteś pewien?</div>
              <div class="text-gray-600 mt-2">
                Czy na prawdę chcesz usunąć tą stronę? <br />
                Tego procesu nie można cofnąć
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Anuluj
              </button>
              <button type="button" class="btn btn-danger w-24">Usuń</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const store = useStore()
    const router = useRouter()
    const items = computed(() => store.state.pages.items)

    const navigate = (name, props = null) => {
      router.push({
        name: name,
        props: props
      })
    }

    const humanFileSize = (bytes, si = false, dp = 1) => {
      const thresh = si ? 1000 : 1024
      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }
      const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      // const units = si
      //   ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      //   : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10 ** dp
      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
      return bytes.toFixed(dp) + ' ' + units[u]
    }

    store.dispatch('pages/fetchItems')

    return {
      navigate,
      items,
      humanFileSize
    }
  }
})
</script>
