const urls = {
  csrfUrl: 'https://cdn.appworks-dev.pl/sanctum/csrf-cookie',
  apiUrl: 'https://cdn.appworks-dev.pl/api/'
}

const api = (uri) => {
  return `${urls.apiUrl}${uri}`
}

const install = app => {
  app.config.globalProperties.$http = window.axios
}

export { install as default, urls as uri, api }
