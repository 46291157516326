<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Dodaj stronę</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <div>
            <label for="page-form-1" class="form-label">Nazwa</label>
            <input
              id="page-form-1"
              type="text"
              class="form-control w-full"
              placeholder="np. cdn"
              v-model="name"
            />
          </div>
          <div class="mt-3">
            <label for="page-form-2" class="form-label">Strona WWW</label>
            <input
                id="page-form-2"
                type="text"
                class="form-control w-full"
                placeholder="np. cdn.appworks-dev.pl"
                v-model="website"
            />
          </div>
          <div class="mt-3">
            <label>Aktywna?</label>
            <div class="mt-2">
              <input v-model="active" type="checkbox" class="form-check-switch" />
            </div>
          </div>
          <div class="text-right mt-5">
            <button type="button" @click="navigate('pages')" class="btn btn-outline-secondary w-24 mr-1">
              Anuluj
            </button>
            <button type="button" class="btn btn-primary w-24">Zapisz</button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
// import { useStore } from '@/store'

export default defineComponent({
  setup() {
    // const store = useStore()
    const router = useRouter()
    // const items = computed(() => store.state.pages.items)
    const name = ref(null)
    const website = ref(null)
    const active = ref(false)

    const navigate = (name, props = null) => {
      router.push({
        name: name,
        props: props
      })
    }

    return {
      navigate,
      name,
      website,
      active
    }
  }
})
</script>
