import * as types from './mutation-types'
import { api } from '@/utils/axios'
import $http from '@/libs/axios'

const state = () => {
  return {
    items: []
  }
}

// getters
const getters = {
  items: state => state.items
}

// actions
const actions = {
  fetchItems({ commit }, items) {
    $http.get(api('pages')).then(({ data }) => {
      commit(types.SET_PAGES_ITEMS, { items: data })
    })
  }
}

// mutations
const mutations = {
  [types.SET_PAGES_ITEMS](state, { items }) {
    state.items = items
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
